<template>
    <div class="content_container customer_terms">
        <div class="content">
            <div class="banner">
                <h1>정보보호 정책</h1>
            </div>
			<div class="m_terms_menu_wrapper">
				<ul class="terms_menu">
					<li class="current">
						<a @click="toggleMenu">이메일 무단수집거부</a>
					</li>
					<li>
						<router-link to="/privacy/privacypolicy" @click="toggleMenu">개인정보 취급방침</router-link>
					</li>
					<li>
						<router-link to="/privacy/privacyprotection" @click="toggleMenu">개인정보 보호규정</router-link>
					</li>
				</ul>
			</div>
            <section class="terms">
				<ul class="terms_menu">
					<li>
						<router-link to="/privacy/privacypolicy">개인정보 취급방침</router-link>
					</li>
					<li>
						<router-link to="/privacy/privacyprotection">개인정보 보호규정</router-link>
					</li>
					<li class="current">
						<router-link to="/privacy/emaildenial">이메일 무단수집거부</router-link>
					</li>
				</ul>
				<div class="text_box"> 
					<div class="csfont2 title" style="color:#000;">이메일 무단수집거부</div>
					<br><br>
					<div class="img_wrapper">
						<img src="@/assets/images/sub/customer/icon_mail_denial.svg" class="icon_mail_denial" alt="이메일 무단수집거부" />
					</div>
				</div>
				<div v-html="this.data">			
				</div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
	data(){
        return {
            data : "",
        }
    },
	mounted(){
		document.querySelector('.content_container').classList.add('loading');
		this.fetchData();
	},
	updated(){
		document.querySelector('.content_container').classList.remove('loading');
	},
	methods : {
		fetchData(){
			this.axios.get('/getEmaildenial')
			.then(res=>{
				if(res.data.success){
					this.data= res.data.content;
				}
				else{
					alert(res.data.message);
				}
			})
		},
		toggleMenu(){
			document.querySelector('.m_terms_menu_wrapper .terms_menu').classList.toggle('on');
		},
	}
}
</script>

<style scoped src="@/assets/css/layout.css">
</style>