<template>
    <!-- 각 게시판 별로 이미지 다르게 -->
    <div class="content_container"
        :class="this.$route.query.whichBoard == 'manageDisclosure' ? 'management_notice_list' 
                : this.$route.query.whichBoard ==  'employment' ? 'employment'
                : this.$route.query.whichBoard ==  'dataRoom' ? 'form_library'
                : 'notice_view'" 
    >
        <div class="content">
            <div class="banner">
                <h1>{{this.boardTitle}}</h1>
            </div>

            <section class="view_content">
                <!-- text START -->
                <div class="text">
                    <div class="title">
                        <p>{{this.data.title}}</p>
                        <span v-if="this.$route.query.whichBoard != 'employment'">{{ dateFormat(this.data.enrollment_date) }}</span>
                    </div>

                    <!-- 진행상태는 채용공고만 -->
                    <div class="employment_status"
                        v-if="this.$route.query.whichBoard == 'employment'"
                    >
                        <div class="employment_div">
                            <div>진행상태</div>
                            <div class="status" :class="this.data.state == '진행중' ? 'progress' : ''">{{this.data.state}}</div>
                        </div>
                        <div class="employment_div">
                            <div>모집기간</div>
                            <div class="date_wrapper">
                                <p class="date">
                                    <span v-if="this.data.always_yn == 'true'">상시채용</span>
                                    <span v-else><span>{{ dateFormat(this.data.start_date) }}  ~ </span><span>{{ dateFormat(this.data.end_date) }}</span></span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="file">
                        <div class="default" v-if="this.file.length == 0">첨부파일이 없습니다.</div>
                        <div v-else>
                            <p>첨부파일 : </p>
                            <p class="file_list">
                                <a v-for="(item, index) in this.file" :key="index"
                                    :href="`${item.path}${item.save_name}`"
                                    target="_blank" 
                                >{{item.original_name}}<br/></a>    
                            </p>
                        </div>
                    </div>

                    <div class="con" v-html="data.content"></div>

                    <div class="btn_text btn_page">
                        <p class="next" v-if="this.next" @click="newDetail(this.next)">
                            다음글 <i class="fas fa-chevron-up"></i>
                            <a>{{this.next.title}}</a>
                        </p>
                        <p class="default" v-else>
                            다음글 <i class="fas fa-chevron-up"></i>
                            <a class="default">다음글이 없습니다.</a>
                        </p>
                        <p class="pre" v-if="this.pre" @click="newDetail(this.pre)">
                            이전글 <i class="fas fa-chevron-down"></i>
                            <a>{{this.pre.title}}</a>
                        </p>
                        <p class="default" v-else>
                            이전글 <i class="fas fa-chevron-down"></i>
                            <a class="default">이전글이 없습니다.</a>
                        </p>
                    </div>
                </div>
                <!-- text END -->

                <div class="btn_wrapper btn_bottom_wrapper">
                    <a @click="toListBoard" class="btn_common">목록</a>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { dateFormat } from '@/utils/dateFormat';

export default {
    name : "DetailBoard",
    data(){
        return {
            data : {},
            file : {},
            next : {},
            pre : {},

            boardTitle : '',
            fromWhere : '',
            url : '',
            detailUrl : '',
        }
    },
    mixins : [ dateFormat ],
    watch : {
        $route(to,from){
            if(to.path == from.path && to.query != from.query){
                this.fetchDetailData();
            }
        }
    },
    created(){
        this.fetchDetailData();
    },
    mounted(){
        document.querySelector('.content_container').classList.add('loading');
    },
    updated(){
        document.querySelector('.content_container').classList.remove('loading');
    },
    methods:{
        // 상세 페이지 마운트시 실행
        fetchDetailData(){
            const whichBoard = this.$route.query.whichBoard;
            // 게시판별로 다른 데이터들 설정
            if(whichBoard == 'manageDisclosure'){
                this.boardTitle = '경영공시';
                this.detailUrl = 'viewManagedisclosure';
                this.fromWhere = '/company/managedisclosure';
            } else if(whichBoard == 'employment'){
                this.boardTitle = '채용정보';
                this.detailUrl = 'viewQualification';
                this.fromWhere = '/company/employment';
            } else if(whichBoard == 'notice'){
                this.boardTitle = '공지사항';
                this.detailUrl = 'viewNoticelist';
                this.fromWhere = '/customer/notice';
            } else if(whichBoard == 'dataRoom'){
                this.boardTitle = '서식자료실';
                this.detailUrl = 'viewDataroom';
                this.fromWhere = '/customer/dataroom';
            } else if(whichBoard == 'customerNotice'){
                this.boardTitle = '고객안내문';
                this.detailUrl = 'viewCustomernotice';
                this.fromWhere = '/customer/customernotice';
            } else{
                this.boardTitle = '약관 및 규정';
                this.detailUrl = 'viewTermscredit';
                this.fromWhere = '/customer/termscredit';
            }

            // 데이터 받아오기
            this.axios.get(`/${this.detailUrl}`, {
                params : { pk : this.$route.query.pk }
            })
            .then(res=>{
                if(res.data.success){
                    this.data= res.data.data;
                    this.file= res.data.file;
                    this.next = res.data.next;
                    this.pre = res.data.pre;
                }
                else{
                    alert(res.data.message);
                }
            }).catch(error=>console.log(error));
        },
        // 이전글, 다음글로 이동
        newDetail(item){
            const whichBoard = this.$route.query.whichBoard;
            const pk = whichBoard == 'manageDisclosure' ? item.managedisclosure_pk   //경영공시
                        : whichBoard == 'employment' ? item.qualification_pk  //채용정보
                        : whichBoard == 'notice' ? item.noticelist_pk  //공지사항
                        : whichBoard == 'dataRoom' ? item.dataroom_pk  //서식자료실
                        : whichBoard == 'customerNotice' ? item.customernotice_pk  //고객안내문
                        : item.termscredit_pk // 약관 및 규정
            this.$router.push({
                path : '/detailboard',
                query : {
                    whichBoard : whichBoard,
                    page : this.$route.query.page,
                    pk : pk,
                }
            }).then(()=>{
                document.querySelector('.content_container').classList.add('loading');
            });
        },
        // 게시판 목록으로 이동
        toListBoard(){
            this.$router.push({
                path : this.fromWhere,
                query : {
                    page : this.$route.query.page,
                }
            });
        }
    }
}
</script>

